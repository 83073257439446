<template>
  <div class="flex flex-col pt-28 pl-8 lg:pl-28">
    <h2 class="text-2xl font-bold leading-7 text-gray-100 sm:text-3xl sm:truncate">
     University Leaderboard
    </h2>
    <p class="text-md pt-8 pb-8 font-bold text-green-400">
      Congratulations to Monash University for being the M-Dash Best University! ❤️‍🔥 🏫 🎉🎉
    </p>
  </div>
  <leaderboard :items="uniItems" />
</template>

<script>
import Leaderboard from '@/components/Leaderboard.vue';
import axios from 'axios';

const googleSheetURL = 'https://spreadsheets.google.com/feeds/list/1nuOHBveWMWl8NJ-b3DUyMqQofqUajYhOmB9mX3fV31E/1/public/values?alt=json';

export default {
  name: 'Universities',
  components: {
    Leaderboard,
  },
  data() {
    return {
      interval: null,
      uniItems: [],
    };
  },
  methods: {
    // Variables to hold the parsed data
    parseData(entries) {
      entries.forEach((value) => {
        const entry = {
          name: value.gsx$name.$t,
          score: value.gsx$score.$t,
          image: value.gsx$image.$t,
          rank: value.gsx$rank.$t,
        };
        this.uniItems.push(entry);
      });
    },

    async fetchData() {
      try {
        const response = await axios.get(googleSheetURL);
        const results = response.data;
        const list = results.feed.entry;

        this.parseData(list);
      } catch (err) {
        if (err.response) {
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
        } else {
          // other errors
        }
      }
    },
  },
  mounted() {
    // this.interval = setInterval(this.fetchData, 5000);
    this.fetchData();
  },
};

</script>

<style>

</style>
