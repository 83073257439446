import { createRouter, createWebHistory } from 'vue-router';
import Universities from '@/views/Universities.vue';
import Dashers from '@/views/Dashers.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Dashers,
  },
  {
    path: '/dashers',
    name: 'dashers',
    component: Dashers,
  },
  {
    path: '/universities',
    name: 'universities',
    component: Universities,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkExactActiveClass: 'bg-gray-900 text-white',
});

export default router;
