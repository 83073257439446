<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="flex flex-col p-8 lg:p-28 ">
    <!-- <p class="text-xs text-gray-500 pb-4 sm:hidden">Slide horizontally to view scores</p> -->
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 ">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-yellow-500">
              <tr>
                <th scope="col" class="px-6 py-3 text-center text-xs font-extrabold
                text-gray-800 uppercase tracking-wider">
                  Rank
                </th>
                <th scope="col" class="px-6 py-3 text-center text-xs font-extrabold
                text-gray-800 uppercase tracking-wider">
                  Name
                </th>
                <!-- <th scope="col" class="px-6 py-3 text-center text-xs font-extrabold
                text-gray-800 uppercase tracking-wider">
                  Score
                </th> -->
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-400 bg-gray-200" >
              <tr v-for="item in items"  :key="item.name">
              <td class="px-6 py-4 whitespace-nowrap text-center" :class="item.rank >= 21
              ? 'bg-red-200' : 'bg-green-200'">
                  <span class="px-2 inline-flex text-xs leading-5 font-semibold
                  rounded-full bg-gray-700 text-gray-100">
                    {{item.rank}}
                  </span>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="flex-shrink-0 h-10 w-10 ">
                      <!-- <img v-if="item.gender === male" class="h-10 w-10 rounded-full"
                      :src="item.image" alt="" /> -->
                      <img class="h-10 w-10 rounded-full" :src="item.image" alt="" />
                    </div>
                    <div class="ml-4 ">
                      <div class="text-sm font-medium text-gray-800">
                        {{ item.name }}
                      </div>
                    </div>
                  </div>
                </td>
                <!-- <td class="px-6 py-4 whitespace-nowrap text-sm font-bold text-center
                text-gray-800 bg-gray-300">
                  {{ item.score }}
                </td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="p-16 text-center text-xs  text-gray-700">
    <h6>Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
    </h6>
    <h6 class="font-bold">Visit us at <a href="https://myroaringcareers.com" title="myroaringcareers">MyRoaringCareers.com</a>
    </h6>
  </div>
</template>

<script>

export default {
//   name: 'Leaderboard',
  props: ['items'],
  methods: {
    isRedZone(item) {
      if (item !== undefined) {
        console.log('RANK: ', item.rank);
        if (item.rank > 3) {
          console.log('TRUE');
          return true;
        }
      }
      return false;
    },
  },
};
</script>
