<template>
  <Disclosure as="nav" class="bg-gray-800" v-slot="{ open }">
    <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
      <div class="relative flex items-center justify-between h-16">
        <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
          <!-- Mobile menu button-->
          <DisclosureButton class="inline-flex items-center justify-center p-2
          rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none
          focus:ring-2 focus:ring-inset focus:ring-white">
            <span class="sr-only">Open main menu</span>
            <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
            <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>
        <div class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
          <div class="flex-shrink-0 flex items-center">
            <img class="block lg:hidden h-8 w-auto" src="../assets/mdash-logo.png" alt="M-Dash" />
            <img class="hidden lg:block h-8 w-auto" src="../assets/mdash-logo.png" alt="M-Dash" />
          </div>
          <div class="hidden sm:block sm:ml-6">
            <div class="flex space-x-4">
              <router-link v-for="item in navigation" :key="item.name"
              :to="{ name: item.routerName }" :class="[item.current ? 'bg-gray-900 text-white' :
              'text-gray-300 hover:bg-gray-700 hover:text-white',
              'px-3 py-2 rounded-md text-sm font-medium']" :aria-current="item.current ?
              'page' : undefined">{{ item.name }}</router-link>

            </div>
          </div>
        </div>
        <div class=" sm:block sm:ml-6 inset-y-0 right-0 flex items-center pr-2 sm:static
        sm:inset-auto sm:ml-6 sm:pr-0">
            <p class="bg-gray-800 text-xs font-bold p-1 text-gray-400">Last Updated:
              <span class="bg-gray-800 text-xs font-extralight p-1 text-gray-400">
                {{lastUpdated}}</span></p>
        </div>
      </div>
    </div>

    <DisclosurePanel class="sm:hidden">
      <div class="px-2 pt-2 pb-3 space-y-1">
        <router-link v-for="item in navigation" :key="item.name" :to="{ name: item.routerName }"
        :class="[item.current ?'bg-gray-900 text-white' :
        'text-gray-300 hover:bg-gray-700 hover:text-white',
        'block px-3 py-2 rounded-md text-base font-medium']" :aria-current="item.current ? 'page'
        : undefined">{{ item.name }}</router-link>
          <div class="p-2 pb-3 pt-3 leading-3">
            <hr class="pt-3 text-gray-800"/>
            <p class="text-xs font-bold p-1 text-gray-400">Last Updated: </p>
            <p class="text-xs font-extralight p-1 text-gray-400">{{lastUpdated}}</p>
        </div>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script>
import { ref } from 'vue';
import {
  Disclosure, DisclosureButton, DisclosurePanel,
} from '@headlessui/vue';
import { MenuIcon, XIcon } from '@heroicons/vue/outline';

const navigation = [
  {
    name: 'Dashers', routerName: 'dashers', to: '/dashers',
  },
  {
    name: 'Universities', routerName: 'universities', to: '/universities',
  },
];

export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    MenuIcon,
    XIcon,
  },
  setup() {
    const open = ref(false);

    return {
      navigation,
      open,
    };
  },
  props: [
    'lastUpdated',
  ],
};
</script>
