<template>
  <div class="flex flex-col pt-28 pl-8 lg:pl-28">
    <h2 class="text-2xl font-bold leading-7 text-gray-100 sm:text-3xl sm:truncate">
     Individual Leaderboard
    </h2>
    <p class="text-md pt-8 pb-8 font-bold text-green-400">
      Congratulations to all Dashers for your performance in the Endgame! 😊 ❤️‍🔥 🎉🎉
    </p>
  </div>
  <leaderboard-dashers :items="dashItems" />
</template>

<script>
import axios from 'axios';
// import face from '@/assets/face.svg';
import boy from '@/assets/boy.svg';
import girl from '@/assets/girl.svg';
import LeaderboardDashers from '../components/LeaderboardDashers.vue';

const googleSheetURL = 'https://spreadsheets.google.com/feeds/list/1nuOHBveWMWl8NJ-b3DUyMqQofqUajYhOmB9mX3fV31E/2/public/values?alt=json';
export default {
  name: 'Universities',
  components: {
    LeaderboardDashers,
  },
  data() {
    return {
      current: true,
      interval: null,
      dashItems: [],
    };
  },
  methods: {
    // Variables to hold the parsed data
    parseData(entries) {
      entries.forEach((value) => {
        const entry = {
          name: value.gsx$name.$t,
          // score: value.gsx$score.$t,
          // image: value.gsx$image.$t,
          gender: value.gsx$gender.$t,
          // eslint-disable-next-line no-nested-ternary
          image: value.gsx$gender.$t === 'Male' ? boy
            : (value.gsx$gender.$t === 'Female' ? girl : value.gsx$image.$t),
          rank: value.gsx$rank.$t,
        };
        this.dashItems.push(entry);
        console.log(this.dashItems);
      });
    },

    async fetchData() {
      try {
        const response = await axios.get(googleSheetURL);
        const results = response.data;
        const list = results.feed.entry;

        this.parseData(list);
      } catch (err) {
        if (err.response) {
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
        } else {
          // other errors
        }
      }
    },
  },
  mounted() {
    // this.interval = setInterval(this.fetchData, 5000);
    this.fetchData();
  },
};

</script>

<style>

</style>
