<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->

  <app-navbar :lastUpdated="lastUpdated"/>
   <router-view />
</template>

<script>
// import AppHeader from './components/Header.vue';
import axios from 'axios';
import AppNavbar from './components/Navbar.vue';

const googleSheetURL = 'https://spreadsheets.google.com/feeds/list/1nuOHBveWMWl8NJ-b3DUyMqQofqUajYhOmB9mX3fV31E/2/public/values?alt=json';

export default {
  name: 'App',
  components: {
    AppNavbar,
  },
  data() {
    return {
      lastUpdated: '',
    };
  },
  methods: {
    async fetchData() {
      try {
        const response = await axios.get(googleSheetURL);
        const results = response.data;
        const list = results.feed.entry;
        this.lastUpdated = list[0].gsx$lastupdated.$t;
      } catch (err) {
        if (err.response) {
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
        } else {
          // other errors
        }
      }
    },
  },
  mounted() {
    // this.interval = setInterval(this.fetchData, 5000);
    this.fetchData();
  },
};

</script>
